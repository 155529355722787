import React from 'react'
import styles from './ContactMail.module.scss';

export const ContactMail = (props) => {
    function mailto() {
        if(props && props.mailto === true) {
            window.location.href = [11,3,8,10,16,13,1,6,13,16,13,7,14,3,6,8,3,2,9,17,14,7,5,12,15,0,3,14].map((item) => ".:@acefgijlmnorstu"[item]).join("");
        }
    }
    let classNames = [styles.contactMailContainer];
    if(props && props.mailto) classNames.push(styles.contactMailContainerClickable);
    return (<span onClick={mailto} onKeyPress={mailto} className={classNames.join(" ")} role="link" tabIndex="0"><span className={styles.contactMailAddress}/>{}</span>)
};
