import React from 'react'
import Layout from '../components/Layout';
import {ContactMail} from '../components/ContactMail';
import {ContactTelegram} from "../components/ContactTelegram";
import { Section } from '../components/Section';
import { graphql } from 'gatsby';
import Seo from "../components/Seo";

const ContactPage = (props) => {
    const image = props.data.allImageSharp.edges[0].node;
    return (
        <Layout location={props.location}>
            <Seo 
                pathname={props.path} 
                titleSuffix={" | Contacto"}
                image={image.fluid.src}
                description={"Información de contacto del fotógrafo profesional Gabriel Jürgens, basado en Buenos Aires, Argentina"}
                aditionalKeywords={"Presupuestos, Armado de catálogos, Fotografía de producto, Books profesionales, Eventos, Tasaciones, Bancos de Imágenes, Consultoria, Información en general"}
            />
            <div style={{paddingTop: "50px", paddingBottom: "50px"}}>
                <Section title={"Contacto"}/>
                <div className={"infoContainer"}>
                    <div className={"infoPictureContainer"} style={{
                        backgroundImage: `url(${image.fluid.base64})`
                    }}>
                        <picture>
                            <source srcSet={image.fluid.srcSetWebp} sizes={image.fluid.sizes} type={"image/webp"}/>
                            <img className={"infoPicture"} src={image.fluid.src} srcSet={image.fluid.srcSet}
                                 sizes={"75vw"} data-sizes={image.fluid.sizes} alt={"Gabriel Jürgens"}/>
                        </picture>
                    </div>
                    <p><strong>Fotógrafo freelance</strong> ubicado en Buenos Aires, Argentina, <strong>Gabriel Jürgens</strong> nació el 3 de octubre de 1979.
                    </p>
                    <p> Realizó varios cursos de fotografía en el <a rel="nofollow noopener noreferrer" href="http://www.rojas.uba.ar/" target="_blank">Centro Cultural Ricardo Rojas</a> de la UBA, entre ellos, uno de <strong>Fotografía Documental</strong> dictado por <a rel="nofollow noopener noreferrer" href="http://www.pablogarber.com.ar/" target="_blank">Pablo Garber</a>, e Idea Fotográfica con <a rel="nofollow noopener noreferrer" href="http://www.albertogoldenstein.com/" target="_blank">Alberto Goldenstein</a>.
                    </p>
                    <p>Es egresado de la carrera de <strong>Fotógrafo Profesional</strong> con título oficial, en el <a rel="nofollow noopener noreferrer" href="http://www.isec.edu.ar/fotografia.php" target="_blank">ISEC</a> y en la actualidad está realizando <strong>trabajos freelance</strong> para <strong>fotografía de producto</strong>, <strong>books</strong>, <strong>eventos sociales</strong>, y otras <strong>producciones fotográficas</strong>.
                    </p>
                </div>
                <div className={"contactReasons"}>
                    <h2 style={{
                        fontSize:"1em",
                        fontWeight:900,
                        marginBottom:"10px",
                        marginLeft: "-10px"
                    }}>Para obtener información personalizada sobre:</h2>
                    <div className={"contactReasonsColumns"}>
                        <ul>
                            <li>• Presupuestos</li>
                            <li>• Armado de catálogos</li>
                            <li>• Fotografía de producto</li>
                            <li>• Books profesionales</li>
                            <li>• Eventos</li>
                            <li>• Tasaciones</li>
                            <li>• Bancos de Imágenes</li>
                            <li>• Consultoria</li>
                            <li>• Información en general</li>
                        </ul>
                    </div>
                    <h2 style={{
                        fontSize:"1em",
                        fontWeight:900,
                        marginTop:"20px",
                        marginBottom:"10px",
                        marginLeft: "-10px"
                    }}>Nos puede contactar via</h2>
                    <div className={"contactReasonsColumns"}>
                        <ul>
                            <li>• Mail: <ContactMail mailto={true}/></li>
                            <li>• Telegram: <ContactTelegram link={true}/></li>
                        </ul>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default ContactPage

export const HomeQuery = graphql`
    query ContactPage {
        allImageSharp(filter: {fields: {data: {sourceInstanceName: {eq: "images"}}}, fluid: {originalName:{eq: "gabriel-jurgens.jpg"}}}) {
            edges {
                node {
                    fluid(quality: 80) {
                        base64
                        aspectRatio
                        src
                        srcSet
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                    }
                    original {
                        width
                        height
                    }
                }
            }
        }
    }
`;
