import React from 'react'
import styles from './ContactTelegram.module.scss';

export const ContactTelegram = (props) => {
    function link() {
        if(props && props.link === true) {
            window.location.href = "https://t.me/"+[1,2,6,4,1,0,3,5].map((item) => "egjnrsu"[item]).join("");
        }
    }
    let classNames = [styles.contactTelegramContainer];
    if(props && props.link) classNames.push(styles.contactTelegramContainerClickable);
    return (<span onClick={link} onKeyPress={link} className={classNames.join(" ")} role="link" tabIndex="0"><span className={styles.contactTelegramAddress}/>{}</span>)
};
